import React, { useState } from 'react'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { Button } from 'components/common/buttons'
import { AiEvaluationRunType, AiEvaluationDatasetType } from 'types/ai/evaluation'
import { DEFAULT_MODELS_PIPELINE } from 'pages/admin/ai/utils'
import useFetch from 'components/common/hooks/useFetch'
import API from 'services/api'
import ReactSelect from 'components/common/react_select'
import ModelsPipelineSelector from 'components/admin/ai/modelsPipelineSelector'

type RunFormData = Partial<AiEvaluationRunType> & {
  jobsCount: number
  datasetId?: string
}

type RunFormProps = {
  onSubmit: (data: RunFormData) => void
  isSaving: boolean
  initialWorkingCopy?: RunFormData
}

const defaultWorkingCopy: RunFormData = {
  description: '',
  jobsCount: 5,
  modelsPipeline: DEFAULT_MODELS_PIPELINE,
}

const RunForm = ({
  onSubmit, isSaving, initialWorkingCopy,
}: RunFormProps) => {
  const [workingCopy, setWorkingCopy] = useState<RunFormData>(
    initialWorkingCopy || defaultWorkingCopy
  )

  const isCreating = !initialWorkingCopy

  const { data: datasets } = useFetch<AiEvaluationDatasetType[]>(
    options => API.admin.ai.evaluation.datasets.fetchAll(options),
    [],
    {
      onSuccess: (datasets) => {
        setWorkingCopy((prev) => {
          if (prev.datasetId) return prev
          if (!isCreating) return prev

          const defaultDataset = datasets.find(d => d.description === 'Default')
          return {
            ...prev,
            datasetId: defaultDataset?.id || datasets[0]?.id,
          }
        })
      },
    }
  )

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target
    setWorkingCopy(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleDatasetChange = (selected: AiEvaluationDatasetType | null) => {
    setWorkingCopy(prev => ({
      ...prev,
      datasetId: selected?.id || '',
    }))
  }

  const handleModelChange = (model: string, type: string) => {
    setWorkingCopy(prev => ({
      ...prev,
      modelsPipeline: {
        ...prev.modelsPipeline,
        [type]: model,
      },
    }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.description?.trim() !== ''

  return (
    <form onSubmit={handleSubmit} className='RunForm'>
      <LabeledFormFieldContainer
        label='Description'
        isRequired
      >
        <input
          name='description'
          value={workingCopy.description}
          onChange={handleChange}
          placeholder='Enter run description'
          className='form-control'
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer label='Dataset'>
        <ReactSelect
          value={datasets?.find(d => d.id === workingCopy.datasetId)}
          onChange={handleDatasetChange}
          options={datasets || []}
          getOptionLabel={option => option.description}
          getOptionValue={option => option.id}
          isClearable
          placeholder='Select a dataset'
          className='form-select'
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer label='Parallel Jobs Count'>
        <input
          type='number'
          name='jobsCount'
          value={workingCopy.jobsCount}
          onChange={handleChange}
          min={1}
          max={30}
          className='form-control'
        />
      </LabeledFormFieldContainer>

      <ModelsPipelineSelector
        modelsPipeline={workingCopy.modelsPipeline || {}}
        onModelChange={handleModelChange}
      />

      <Button
        type='submit'
        disabled={isSaving || !isValid}
        showLoadingSpinner={isSaving}
      >
        {isSaving ? 'Creating...' : 'Create Run'}
      </Button>
    </form>
  )
}

export default RunForm
