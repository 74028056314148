import React, { useMemo, useState } from 'react'
import { ButtonSmallNarrow } from 'components/common/buttons'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import API from 'services/api'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useApi from 'components/common/hooks/useApi'

type ExtractKeyPointsProps = {
  expectedAnswers: string[]
  onUseExpectedAnswer: (answer: string) => void
}

const DEFAULT_PROMPT = `Given these expected answers, extract and combine the key points into a single, comprehensive answer. Maintain accuracy and clarity:

{{ANSWERS}}

Please provide a well-structured response that:
1. Combines overlapping points
2. Preserves unique insights
3. Maintains technical accuracy
4. Is clear and concise
5. Format in html inside \`\`\`html\`\`\`.

First reason the key points in each answer, determine a score of level of importance, then combine the key points into a single, comprehensive answer.
Only include key points that are included in at least {{THRESHOLD}}% of the answers.

Example:

<h3>Threshold: 75%</h3>

<h4>Answer 1:</h4>
<ul>
  <li>Key point 1</li>
  <li>Key point 2</li>
  <li>Key point 3</li>
  <li>Link to source 1</li>
</ul>

<h4>Answer 2:</h4>
<ul>
  <li>Key point 1</li>
  <li>Key point 2</li>
  <li>Key point 4</li>
  <li>Link to source 1</li>
</ul>

<h4>Reasoning:</h4>
<ul>
  <li>The key point 1 is included in answer 1 and 2, which means it's included in 100% of the answers, hence it should be included in the combined answer</li>
  <li>The key point 2 is included in answer 1 and 2, which means it's included in 100% of the answers, hence it should be included in the combined answer</li>
  <li>The key point 3 is only in answer 1, which means it's included in 50% of the answers, hence it should not be included in the combined answer</li>
  <li>The key point 4 is only in answer 2, which means it's included in 50% of the answers, hence it should not be included in the combined answer</li>
  <li>The link to source 1 is included in all answers, which means it's included in 100% of the answers, hence it should be included in the combined answer</li>
</ul>

<h4>Combined Answer:</h4>
\`\`\`html
- Key point 1
- Key point 2
- Link to source 1
\`\`\`
`

const ExtractKeyPointsFromExpectedAnswers = ({ expectedAnswers, onUseExpectedAnswer }: ExtractKeyPointsProps) => {
  const [callLLM, { data, isLoading }] = useApi(API.admin.ai.callLLM)
  const [prompt, setPrompt] = useState(DEFAULT_PROMPT)
  const [threshold, setThreshold] = useState(75)
  const [isPromptVisible, setIsPromptVisible] = useState(false)
  const [showRawHtml, setShowRawHtml] = useState(false)
  const [showRawRest, setShowRawRest] = useState(false)

  const answers = useMemo(() => expectedAnswers.map((answer, i) => `<answer number="${i + 1}">${answer}</answer>`).join('\n\n'), [expectedAnswers])

  const finalPrompt = useMemo(
    () => prompt.replace('{{ANSWERS}}', answers).replace('{{THRESHOLD}}', threshold.toString()),
    [prompt, answers, threshold]
  )

  const args = useMemo(() => JSON.stringify({
    model: 'gpt-4o',
    messages: [
      {
        role: 'user',
        content: finalPrompt,
      },
    ],
    type: 'langchain_client',
    method: 'chat',
    max_tokens: 4096,
  }), [finalPrompt])

  const handleExtractKeyPoints = () => {
    callLLM({ args })
  }

  const { extractedHtml, restContent } = useMemo(() => {
    if (!data?.response) return { extractedHtml: '', restContent: '' }

    let html = ''
    let rest = data.response

    if (data.response.includes('```html')) {
      const parts = data.response.split('```html')
      rest = parts[0].trim()
      html = parts[1].split('```')[0].trim()
    } else if (data.response.includes('<h4>Combined Answer:</h4>')) {
      const parts = data.response.split('<h4>Combined Answer:</h4>')
      rest = parts[0].trim()
      html = parts[1].trim()
    } else {
      html = data.response.trim()
    }

    return { extractedHtml: html, restContent: rest }
  }, [data?.response])

  const handleOnUseExpectedAnswer = () => {
    onUseExpectedAnswer(extractedHtml)
  }

  if (expectedAnswers.length === 0) return null

  return (
    <div className='mt-4 border-top pt-4'>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h5>Extract Key Points from Expected Answers</h5>
        <div className='d-flex gap-2'>
          <ButtonSmallNarrow
            type='button'
            variant='secondary'
            onClick={() => setIsPromptVisible(!isPromptVisible)}
          >
            {isPromptVisible ? 'Hide Prompt' : 'Edit Prompt'}
          </ButtonSmallNarrow>
          <ButtonSmallNarrow
            type='button'
            variant='secondary'
            onClick={handleExtractKeyPoints}
            disabled={isLoading}
          >
            Run Extraction
          </ButtonSmallNarrow>
          {data?.response && (
            <ButtonSmallNarrow
              type='button'
              onClick={handleOnUseExpectedAnswer}
            >
              Use as Expected Answer
            </ButtonSmallNarrow>
          )}
        </div>
      </div>
      <div className='mb-2'>
        <label className='form-label'>Percentage of answers that must include the key point (%)</label>
        <input
          type='number'
          className='form-control'
          value={threshold}
          onChange={e => setThreshold(Math.max(0, Math.min(100, parseInt(e.target.value) || 0)))}
          min={0}
          max={100}
        />
      </div>
      {isPromptVisible && (
        <div className='mb-3'>
          <textarea
            className='form-control'
            value={prompt}
            onChange={e => setPrompt(e.target.value)}
            rows={20}
          />
          <div className='d-flex justify-content-end mt-2'>
            <ButtonSmallNarrow
              type='button'
              variant='secondary'
              onClick={() => setPrompt(DEFAULT_PROMPT)}
            >
              Reset to Default
            </ButtonSmallNarrow>
          </div>
        </div>
      )}
      {isLoading && <CirclesLoadingIndicator />}
      {data?.response && (
        <div className='mt-3'>
          {restContent && (
            <div dangerouslySetInnerHTML={{ __html: restContent }} />
          )}
          <div className='d-flex justify-content-between align-items-center mb-2 mt-3'>
            <h6>Extracted HTML:</h6>
            <ButtonSmallNarrow
              type='button'
              variant='secondary'
              onClick={() => setShowRawHtml(!showRawHtml)}
            >
              {showRawHtml ? 'Show Formatted' : 'Show Raw HTML'}
            </ButtonSmallNarrow>
          </div>
          {showRawHtml ? (
            <pre className='bg-light p-3 rounded'><code>{extractedHtml}</code></pre>
          ) : (
            <AiAnswerContent content={extractedHtml} />
          )}
        </div>
      )}
    </div>
  )
}

export default ExtractKeyPointsFromExpectedAnswers
