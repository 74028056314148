import React from 'react'
import useApi from 'components/common/hooks/useApi'
import DatasetForm from 'pages/admin/ai/datasets/datasetForm'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { useHistory, useParams } from 'react-router-dom'
import BackButton from 'components/common/backButton'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import AiEvaluationQuestionsTable from 'pages/admin/ai/aiEvaluationQuestionsTable'

const EditDatasetPage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { data: dataset, isLoaded } = useFetch(() => API.admin.ai.evaluation.datasets.fetch(id))

  const [updateDataset, { isLoading: isSaving }] = useApi(
    API.admin.ai.evaluation.datasets.update,
    {
      toastSuccessMessage: 'Dataset updated',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push('/admin/ai_evaluation')
      },
    }
  )

  const [deleteDataset, { isLoading: isDeleting }] = useApi(
    API.admin.ai.evaluation.datasets.destroy,
    {
      toastSuccessMessage: 'Dataset deleted',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push('/admin/ai_evaluation')
      },
    }
  )

  const handleSubmit = (data: any) => {
    updateDataset({ id, ...data })
  }

  const handleDelete = () => {
    deleteDataset({ id })
  }

  if (!isLoaded) return <CirclesLoadingIndicator />

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Edit Dataset</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />
        <DatasetForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isSaving={isSaving}
          isDeleting={isDeleting}
          initialWorkingCopy={dataset}
          showDelete
        />

        <div className='mt-5'>
          <AiEvaluationQuestionsTable datasetId={id} />
        </div>
      </main>
    </>
  )
}

export default EditDatasetPage
