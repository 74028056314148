import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import React, { useState } from 'react'
import API from 'services/api'
import { AiEvaluationRunType } from 'types/ai/evaluation'
import DateCell from 'components/common/tables/dateCell'
import { i18nPath } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import { ButtonLink, Button } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import FormattedScoreWithLabel from 'components/admin/ai/formattedScoreWithLabel'

const I18N = i18nPath('views.ai_evaluation.runs')
const I18N_EVAL = i18nPath('views.platform_analytics.ai_answers.evaluation')

const AiEvaluationRunsTable = () => {
  const history = useHistory()
  const [selectedRuns, setSelectedRuns] = useState<string[]>([])
  const {
    data, isLoading, paginationData, callApi, isLoaded,
  } = useFetch<AiEvaluationRunType[]>(
    options => API.admin.ai.evaluation.runs.fetchAll({ ...options, perPage: 10 })
  )

  const columns = [
    {
      header: '',
      style: { width: '50px' },
      col: 'col-select',
      accessor: (run: AiEvaluationRunType) => (
        <input
          type='checkbox'
          checked={selectedRuns.includes(run.id)}
          onChange={(e) => {
            e.stopPropagation()
            setSelectedRuns(prev => (e.target.checked
              ? [...prev, run.id]
              : prev.filter(id => id !== run.id))
            )
          }}
          onClick={e => e.stopPropagation()}
        />
      ),
    },
    {
      header: I18N('table_header.created_at'),
      col: 'col-created-at',
      accessor: (run: AiEvaluationRunType) => <DateCell date={run.createdAt} />,
    },
    {
      header: I18N('table_header.description'),
      col: 'col-description',
      accessor: (run: AiEvaluationRunType) => (
        <div>
          <span>{run.description}</span>
          {run.dataset && (
            <div className='text-secondary mt-1'>
              <small>Dataset: {run.dataset.description}</small>
            </div>
          )}
          {run.status === 'in_progress' && (
            <div className='text-secondary mt-1'>
              <small>
                Running - {run.remainingQuestionsCount} questions remaining <CdnSvg className='SyncingIndicator importing' src='/images/syncIcon.svg' />
              </small>
            </div>
          )}
          {run.status === 'failed' && (
            <div className='text-danger mt-1'>
              <small>
                Failed - {run.remainingQuestionsCount} questions not processed
              </small>
            </div>
          )}
        </div>
      ),
    },
    {
      header: 'Latency',
      col: 'col-latency',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageLatencyTime}
          hideScoreLabel
          unit='s'
        />
      ),
    },
    {
      header: 'Cost',
      col: 'col-cost',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.totalCost}
          hideScoreLabel
          unit='$'
          isUnitBeforeScore
          precision={6}
        />
      ),
    },
    {
      header: 'Score',
      col: 'col-score',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageScore}
          tooltipContent={I18N_EVAL('expected_answer_score_help_text')}
        />
      ),
    },
    {
      header: 'Faithfulness',
      col: 'col-faithfulness',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageFaithfulnessScore}
          tooltipContent={I18N_EVAL('faithfulness_score_help_text')}
        />
      ),
    },
    {
      header: 'Answer Relevance',
      col: 'col-answer-relevance',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageAnswerRelevanceScore}
          tooltipContent={I18N_EVAL('answer_relevance_score_help_text')}
        />
      ),
    },
    {
      header: 'Context Relevance',
      col: 'col-context-relevance',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageContextRelevanceScore}
          tooltipContent={I18N_EVAL('context_relevance_score_help_text')}
        />
      ),
    },
    {
      header: 'Conciseness',
      col: 'col-conciseness',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageConcisenessScore}
          tooltipContent={I18N_EVAL('answer_conciseness_score_help_text')}
        />
      ),
    },
    {
      header: 'Ragas',
      col: 'col-ragas',
      accessor: (run: AiEvaluationRunType) => (
        <FormattedScoreWithLabel
          score={run.metrics.averageRagasScore}
          tooltipContent={I18N_EVAL('ragas_score_help_text')}
        />
      ),
    },
  ]

  const onRowClick = (run: AiEvaluationRunType) => {
    history.push(`/admin/ai_evaluation/${run.id}`)
  }

  const onCompareClick = () => {
    if (selectedRuns.length < 1) return
    history.push(`/admin/ai_evaluation/runs/compare?runIds=${selectedRuns.join(',')}`)
  }

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3>{I18N('title')}</h3>

        <div className='d-flex gap-2'>
          {selectedRuns.length >= 1 && (
            <Button onClick={onCompareClick} className='mr-2'>
              Compare Selected ({selectedRuns.length})
            </Button>
          )}
          <ButtonLink to='/admin/ai_evaluation/runs/new'>
            <CdnSvg src='/images/plusIcon.svg' className='mr-2 create-run-icon' style={{ width: '15px' }} />
            Create Run
          </ButtonLink>
        </div>
      </div>

      <SmartTable
        isLoading={isLoading}
        LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
        columns={columns}
        data={data}
        className='white-bg-table table-with-row-hover'
        showPagination
        page={paginationData.page}
        pages={paginationData.totalPages}
        onPaginationClick={callApi}
        onSortChange={() => {}}
        sortKey='created_at'
        sortedAsc={false}
        onRowClick={onRowClick}
      />
    </div>
  )
}

export default AiEvaluationRunsTable
