import BackButton from 'components/common/backButton'
import ClearyCard from 'components/common/card'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ClearyTooltip from 'components/common/clearyTooltip'
import CollapsableItem from 'components/common/collapsableItem'
import useFetch from 'components/common/hooks/useFetch'
import UserLink from 'components/common/userLink'
import { formatFloat } from 'components/common/utils'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import Sources from 'components/search/ai/sources'
import ScoreLabel from 'pages/admin/ai/runScoreLabel'
import React, { useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import API from 'services/api'
import { AiEvaluationAnswerType } from 'types/ai/evaluation'
import { I18NCommon, i18nPath } from 'utils/i18nHelpers'
import EditableLlmCall from './aiEvaluationAnswerComponents/editableLlmCall'

const I18N = i18nPath('views.ai_evaluation.answer')
const I18N_EVAL = i18nPath('views.platform_analytics.ai_answers.evaluation')

const VerdictBadge = ({ verdict }: { verdict: string }) => {
  const isYes = verdict.toLowerCase().includes('yes')
  const className = `badge badge-${isYes ? 'success' : 'danger'} ml-1`
  return <span className={className}>{verdict}</span>
}

const formatExplanationWithVerdict = (text: string) => text.replace(/(Yes|No)\./g, (match, verdict) => `<span class="badge badge-${verdict.toLowerCase() === 'yes' ? 'success' : 'danger'} ml-1">${verdict}</span>`)

const AiEvaluationAnswerPage = () => {
  const { answerId } = useParams()
  const {
    data, isLoaded,
  } = useFetch<AiEvaluationAnswerType>(API.admin.ai.evaluation.answers.fetch, [answerId])

  const [isAllLLMCallsCollapsed, setIsAllLLMCallsCollapsed] = useState(true)

  const formattedExpectedAnswerExplanation = useMemo(() => {
    if (!data?.expectedAnswerExplanation) return null
    const lines = data.expectedAnswerExplanation.split('\n')
    return lines.map(line => formatExplanationWithVerdict(line)).join('<br />')
  }, [data?.expectedAnswerExplanation])

  if (!isLoaded) return <CirclesLoadingIndicator />

  if (!data) return <div>No data</div>


  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>{I18N('title', { answerId })}</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url={`/admin/ai_evaluation/${data.run.id}`} className='mb-4' />

        <ClearyCard className='mb-3'>
          <h4>
            {I18NCommon('question')}
            <Link
              to={`/admin/ai_evaluation/questions/${data.question.id}/edit`}
              className='btn btn-link btn-sm ml-2'
            >
              Edit
            </Link>
          </h4>
          <p>{data.question.content}</p>

          <h4>Asked by</h4>
          <div className='mt-2'><UserLink user={data.question.user} /></div>

          <div className='mt-1 mb-4 text-secondary text-small'>
            {data.question.companyLogoUrl ? (
              <img
                src={data.question.companyLogoUrl}
                alt={data.question.companyName}
                className='mr-2'
                style={{ maxWidth: '100px', maxHeight: '50px' }}
              />
            ) : (
              <div>{data.question.companyName}</div>
            )}
          </div>

          <h4>Evaluation</h4>
          <div className='mb-4 d-flex gap-3'>
            <div>
              <h6>Tokens</h6>
              <p>{data.totalTokens}</p>
            </div>

            <div>
              <h6>Latency</h6>
              <p>{data.latencyTime ? `${formatFloat(data.latencyTime, 2)}s` : 'N/A'}</p>
            </div>

            <div>
              <h6>Cost</h6>
              <p>${formatFloat(data.totalCost, 6)}</p>
            </div>

            <div>
              <h6>
                Answer Score
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('expected_answer_score_help_text')}
                </ClearyTooltip>
              </h6>

              <ScoreLabel score={data.expectedAnswerScore} /> {formatFloat(data.expectedAnswerScore || 0, 2)}
            </div>

            <div>
              <h6>
                Faithfulness
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('faithfulness_score_help_text')}
                </ClearyTooltip>
              </h6>

              <ScoreLabel score={data.faithfulnessScore} /> {formatFloat(data.faithfulnessScore || 0, 2)}
            </div>

            <div>
              <h6>
                Answer Relevance
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('answer_relevance_score_help_text')}
                </ClearyTooltip>
              </h6>

              <ScoreLabel score={data.answerRelevanceScore} /> {formatFloat(data.answerRelevanceScore || 0, 2)}
            </div>

            <div>
              <h6>
                Context Relevance
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('context_relevance_score_help_text')}
                </ClearyTooltip>
              </h6>
              <ScoreLabel score={data.contextRelevanceScore} /> {formatFloat(data.contextRelevanceScore || 0, 2)}
            </div>

            <div>
              <h6>
                Answer Conciseness
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('answer_conciseness_score_help_text')}
                </ClearyTooltip>
              </h6>
              <ScoreLabel score={data.answerConcisenessScore} /> {formatFloat(data.answerConcisenessScore || 0, 2)}
            </div>

            <div>
              <h6>
                Ragas Score
                <ClearyTooltip className='ml-1' placement='top'>
                  {I18N_EVAL('ragas_score_help_text')}
                </ClearyTooltip>
              </h6>
              <ScoreLabel score={data.ragasScore} /> {formatFloat(data.ragasScore || 0, 2)}
            </div>

          </div>

          <div className='d-flex gap-4 mb-4 align-items-start'>
            <div className='flex-grow-1 flex-shrink-1' style={{ width: 0 }}>
              <h4>{I18NCommon('answer')}</h4>
              <AiAnswerContent content={data.answer} />
              {data.sources && <Sources sources={data.sources} />}
            </div>

            {data.expectedAnswer && (
              <div className='flex-grow-1 flex-shrink-1' style={{ width: 0 }}>
                <h4>{I18NCommon('expected_answer')}</h4>
                <AiAnswerContent content={data.expectedAnswer} />
                {data.question.sources && <Sources sources={data.question.sources} />}
              </div>
            )}
          </div>

          {formattedExpectedAnswerExplanation && (
            <>
              <h4>{I18NCommon('expected_answer_explanation')}</h4>
              <CollapsableItem
                label={(
                  <button className='btn btn-secondary'>View expected answer explanation</button>
                )}
                defaultIsCollapsed={true}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: formattedExpectedAnswerExplanation,
                  }}
                />
              </CollapsableItem>
            </>
          )}

          <h4>{I18NCommon('context')}</h4>
          <CollapsableItem
            label={(
              <button className='btn btn-secondary'>View context</button>
            )}
            defaultIsCollapsed={true}
          >
            <pre style={{ whiteSpace: 'pre-wrap' }}>{data.context}</pre>
          </CollapsableItem>
        </ClearyCard>

        <button className='btn btn-primary mb-3' onClick={() => setIsAllLLMCallsCollapsed(value => !value)}>Toggle all LLM calls</button>

        {data.llmCalls.map((llmCall, index) => (
          <EditableLlmCall
            key={`llm-call-${index}-${isAllLLMCallsCollapsed}`}
            llmCall={llmCall}
            defaultIsCollapsed={isAllLLMCallsCollapsed}
          />
        ))}
      </main>
    </>
  )
}

export default AiEvaluationAnswerPage
