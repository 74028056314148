import React from 'react'
import useApi from 'components/common/hooks/useApi'
import DatasetForm from 'pages/admin/ai/datasets/datasetForm'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { useHistory } from 'react-router-dom'
import BackButton from 'components/common/backButton'

const AddDatasetPage = () => {
  const history = useHistory()
  const [createDataset, { isLoading: isSaving }] = useApi(
    API.admin.ai.evaluation.datasets.create,
    {
      toastSuccessMessage: 'Dataset created',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: ({ id }) => {
        history.push(`/admin/ai_evaluation/datasets/${id}/edit`)
      },
    }
  )

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Create Dataset</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url='/admin/ai_evaluation' className='mb-4' />
        <DatasetForm onSubmit={createDataset} isSaving={isSaving} />
      </main>
    </>
  )
}

export default AddDatasetPage
