/* eslint-disable import/prefer-default-export */

export const DEFAULT_MODELS_PIPELINE = {
  convertQueryToQuestion: 'gpt-4o-mini',
  extractTermsFromQuestion: 'gpt-4o-mini',
  agentRouter: 'gpt-4o',
  generateAnswer: 'gpt-4o',
  userAssistant: 'gpt-4o-mini',
}

export const MODELS_PIPELINE_HUMAN_READABLE_NAMES = {
  convertQueryToQuestion: '1. Convert Query to Question',
  extractTermsFromQuestion: '2. Extract Terms from Question',
  agentRouter: '3. Agent Router',
  generateAnswer: '4a. Generate Answer',
  userAssistant: '4b. User Assistant',
}

export const MODEL_OPTIONS = [
  { value: 'gpt-4o', label: 'GPT-4o' },
  { value: 'gpt-4o-mini', label: 'GPT-4o Mini' },
  { value: 'o1', label: 'o1 (Very expensive, avoid it)' },
  { value: 'o1-mini', label: 'o1 Mini' },
  { value: 'o3-mini', label: 'o3 Mini' },
]

export const DEFAULT_THRESHOLD = 0.7
