import React from 'react'
import useApi from 'components/common/hooks/useApi'
import QuestionForm from 'pages/admin/ai/questions/questionForm'
import API from 'services/api'
import { I18NCommon } from 'utils/i18nHelpers'
import { useHistory, useParams } from 'react-router-dom'
import BackButton from 'components/common/backButton'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'

const EditQuestionPage = () => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()

  const { data: question, isLoaded } = useFetch(() => API.admin.ai.evaluation.questions.fetch(id))

  const [updateQuestion, { isLoading: isSaving }] = useApi(
    API.admin.ai.evaluation.questions.update,
    {
      toastSuccessMessage: 'Question updated',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push(`/admin/ai_evaluation/datasets/${question.dataset.id}/edit`)
      },
    }
  )

  const [deleteQuestion, { isLoading: isDeleting }] = useApi(
    API.admin.ai.evaluation.questions.destroy,
    {
      toastSuccessMessage: 'Question deleted',
      toastErrorMessage: I18NCommon('generic_error'),
      onSuccess: () => {
        history.push(`/admin/ai_evaluation/datasets/${question.dataset.id}/edit`)
      },
    }
  )

  const handleSubmit = (data: any) => {
    updateQuestion({ id, ...data })
  }

  const handleDelete = () => {
    deleteQuestion({ id })
  }

  if (!isLoaded) return <CirclesLoadingIndicator />

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Edit Question</h3>
      </header>

      <main className='AdminContent'>
        <BackButton url={`/admin/ai_evaluation/datasets/${question.dataset.id}/edit`} className='mb-4' />
        <QuestionForm
          onSubmit={handleSubmit}
          onDelete={handleDelete}
          isSaving={isSaving}
          isDeleting={isDeleting}
          initialWorkingCopy={question}
          showDelete
        />
      </main>
    </>
  )
}

export default EditQuestionPage
