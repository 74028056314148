import { Button, ButtonSmallNarrow } from 'components/common/buttons'
import useSSE from 'components/common/hooks/useSSE'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import Sources from 'components/search/ai/sources'
import React, { useMemo, useState } from 'react'
import API from 'services/api'
import { AiEvaluationQuestionType, AiEvaluationDatasetType } from 'types/ai/evaluation'
import camelCaseKeys from 'utils/camelCaseKeys'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ClearyCard from 'components/common/card'
import { present } from 'components/common/utils'
import { DEFAULT_MODELS_PIPELINE, DEFAULT_THRESHOLD } from 'pages/admin/ai/utils'
import snakeCaseKeys from 'utils/snakeCaseKeys'
import ModelsPipelineSelector from 'components/admin/ai/modelsPipelineSelector'
import TiptapEditor from 'components/common/tiptap/tiptapEditor'
import ReactSelect from 'components/common/react_select'
import useFetch from 'components/common/hooks/useFetch'
import ExtractKeyPointsFromExpectedAnswers from './extractKeyPointsFromExpectedAnswers'

type LlmRunArgs = {
  id: string
  query: string
  userId: string
  timestamp: string
  answer?: string
  modelsPipeline: Record<string, string>
}

type RunLLMCallProps = {
  args: LlmRunArgs
  onUseAsExpectedAnswer: (answer: string, sources: any[]) => void
  onUseSources: (sources: any[]) => void
  onRemove: (id: string) => void
  onUpdate: (run: LlmRunArgs) => void
}

const RunLLMCall = ({
  args,
  onUseAsExpectedAnswer,
  onUseSources,
  onRemove,
  onUpdate,
}: RunLLMCallProps) => {
  const [answer, setAnswer] = useState<string>('')
  const [sources, setSources] = useState<any[]>([])
  const [isGenerating, setIsGenerating] = useState<boolean>(true)

  useSSE('/api/admin/ai/assistant/llm/run_search', {
    options: {
      headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': API.csrfToken() },
      method: 'POST',
      payload: JSON.stringify({
        query: args.query,
        user_id: args.userId,
        use_experimental_ai_assistant: false,
        models_pipeline: args.modelsPipeline,
      }),
    },
    listeners: {
      'delta-received': (event) => {
        setAnswer(prev => prev + event.data)
      },
      'workflow-finished': (event) => {
        const data = JSON.parse(event.data)
        setSources(camelCaseKeys(data.sources))
        setIsGenerating(false)
        onUpdate({
          ...args,
          answer: data.message,
        })
      },
      'error': (event) => {
        console.error(event.data)
      },
    },
    listen: true,
  })

  return (
    <div className='mt-4 border-bottom pb-4'>
      <div className='d-flex justify-content-between align-items-center mb-2'>
        <h5>Run {new Date(args.timestamp).toLocaleString()}</h5>
        <div className='d-flex gap-2'>
          {!isGenerating && (
            <>
              <ButtonSmallNarrow
                type='button'
                onClick={() => onUseAsExpectedAnswer(answer, sources)}
                disabled={false}
              >
                Use as Expected Answer
              </ButtonSmallNarrow>
              <ButtonSmallNarrow
                type='button'
                variant='secondary'
                onClick={() => onUseSources(sources)}
                disabled={!sources.length}
              >
                Use Sources
              </ButtonSmallNarrow>
            </>
          )}
          <ButtonSmallNarrow
            type='button'
            variant='secondary-danger'
            onClick={() => onRemove(args.id)}
          >
            Remove
          </ButtonSmallNarrow>
        </div>
      </div>
      {isGenerating && <CirclesLoadingIndicator />}
      <AiAnswerContent content={answer} />
      {!isGenerating && sources && <Sources sources={sources} />}
    </div>
  )
}

type QuestionFormData = Partial<AiEvaluationQuestionType> & {
  modelsPipeline?: Record<string, string>
}

type QuestionFormProps = {
  onSubmit: (data: QuestionFormData) => void
  onDelete?: () => void
  isSaving: boolean
  isDeleting?: boolean
  initialWorkingCopy?: QuestionFormData
  showDelete?: boolean
  initialDatasetId?: string
}

const QuestionForm = ({
  onSubmit, onDelete, isSaving, isDeleting, initialWorkingCopy, showDelete, initialDatasetId,
}: QuestionFormProps) => {
  const [editorCounter, setEditorCounter] = useState<number>(0)
  const [workingCopy, setWorkingCopy] = useState<QuestionFormData>(
    {
      content: '',
      expectedAnswer: '',
      sources: [],
      modelsPipeline: DEFAULT_MODELS_PIPELINE,
      ...initialWorkingCopy,
    }
  )
  const [runs, setRuns] = useState<LlmRunArgs[]>([])
  const [generateCount, setGenerateCount] = useState<number>(10)

  const { data: datasets } = useFetch<AiEvaluationDatasetType[]>(
    options => API.admin.ai.evaluation.datasets.fetchAll(options),
    [], {
      onSuccess: (datasets) => {
        if (initialDatasetId) {
          const dataset = datasets.find(dataset => dataset.id === initialDatasetId)
          if (dataset && !workingCopy.dataset) {
            setWorkingCopy(prev => ({ ...prev, dataset }))
          }
        }
      },
    }
  )

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target
    setWorkingCopy(prev => ({ ...prev, [name]: value }))
  }

  const handleExpectedAnswerChange = (html: string) => {
    setWorkingCopy(prev => ({ ...prev, expectedAnswer: html }))
  }

  const handleUserChange = (selectedUser: any) => {
    setWorkingCopy(prev => ({ ...prev, user: selectedUser }))
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const submitter = (e.nativeEvent as any).submitter as HTMLButtonElement
    // We need to prevent the form from being submitted when people clicks in buttons inside the tiptap editor, since it's triggering the submit event.
    if (submitter?.closest('.tiptap-editor-card')) {
      e.stopPropagation()
      return
    }
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.content && workingCopy.content.trim() !== '' && !!workingCopy.user

  const handleGenerateAnswer = () => {
    if (!workingCopy.content || !workingCopy.user) return

    const newRuns: LlmRunArgs[] = Array.from({ length: generateCount }, () => ({
      id: crypto.randomUUID(),
      query: workingCopy.content!,
      userId: workingCopy.user!.id,
      timestamp: new Date().toISOString(),
      modelsPipeline: snakeCaseKeys(workingCopy.modelsPipeline),
    }))
    setRuns(prev => [...prev, ...newRuns])
  }

  const handleClearRuns = () => {
    setRuns([])
  }

  const handleUseAsExpectedAnswer = (answer: string, sources: any[]) => {
    setEditorCounter(prev => prev + 1)
    setWorkingCopy(prev => ({
      ...prev,
      expectedAnswer: answer,
      sources,
    }))
  }

  const handleUseSources = (sources: any[]) => {
    setWorkingCopy(prev => ({
      ...prev,
      sources,
    }))
  }

  const handleUpdate = (run: LlmRunArgs) => {
    setRuns(prev => prev.map(r => (r.id === run.id ? run : r)))
  }

  const handleUseExtractedAnswer = (answer: string) => {
    setEditorCounter(prev => prev + 1)
    setWorkingCopy(prev => ({
      ...prev,
      expectedAnswer: answer,
    }))
  }

  const handleRemoveRun = (id: string) => {
    setRuns(prev => prev.filter(run => run.id !== id))
  }

  const answers = useMemo(() => runs.map(run => run.answer).filter(present), [runs])

  const handleDatasetChange = (selected: AiEvaluationDatasetType | null) => {
    setWorkingCopy(prev => ({
      ...prev,
      dataset: selected || undefined,
    }))
  }

  return (
    <form onSubmit={handleSubmit} className='AiEvaluationQuestionForm'>
      <LabeledFormFieldContainer label='Dataset'>
        <ReactSelect
          value={workingCopy.dataset}
          onChange={handleDatasetChange}
          options={datasets || []}
          getOptionLabel={option => option.description}
          getOptionValue={option => option.id}
          isClearable
          placeholder='Select a dataset'
          className='form-select'
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label='Question'
        isRequired
      >
        <textarea
          rows={3}
          name='content'
          value={workingCopy.content}
          onChange={handleChange}
          placeholder='Enter question'
          required
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label='User'
      >
        <EmployeeSearch
          onChange={handleUserChange}
          selectedEmployee={workingCopy.user}
          isClearable
          searchAcrossCompanies
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label='Expected Answer'
        hasTiptapEditor
      >
        <ClearyCard className='z-index-0 mb-4 tiptap-editor-card'>
          <TiptapEditor
            key={editorCounter}
            html={workingCopy.expectedAnswer}
            onChange={handleExpectedAnswerChange}
            className='TiptapEditor'
            configuration={{
              placeholder: 'Select a run below or enter expected answer manually',
              focusOnInitialize: false,
            }}
          />
        </ClearyCard>
      </LabeledFormFieldContainer>

      {workingCopy.sources && workingCopy.sources.length > 0 && (
        <div className='d-flex align-items-center gap-2'>
          <Sources sources={workingCopy.sources} />
          <ButtonSmallNarrow
            type='button'
            variant='secondary-danger'
            onClick={() => setWorkingCopy(prev => ({ ...prev, sources: [] }))}
          >
            Clear Sources
          </ButtonSmallNarrow>
        </div>
      )}

      <ClearyCard className='mb-4'>
        <ModelsPipelineSelector
          modelsPipeline={workingCopy.modelsPipeline || DEFAULT_MODELS_PIPELINE}
          onModelChange={(model, type) => setWorkingCopy(prev => ({
            ...prev,
            modelsPipeline: {
              ...prev.modelsPipeline,
              [type]: model,
            },
          }))}
        />

        <div className='d-flex align-items-center gap-2'>
          <input
            type='number'
            min='1'
            max='30'
            value={generateCount}
            onChange={e => setGenerateCount(Math.min(30, Math.max(1, parseInt(e.target.value) || 1)))}
            style={{ width: '60px' }}
            className='form-control'
          />
          <ButtonSmallNarrow
            type='button'
            variant='secondary'
            onClick={handleGenerateAnswer}
            disabled={!workingCopy.content || !workingCopy.user}
          >
            Generate Expected Answer
          </ButtonSmallNarrow>
          {runs.length > 0 && (
            <ButtonSmallNarrow
              type='button'
              variant='secondary-danger'
              onClick={handleClearRuns}
            >
              Clear All Expected Answers
            </ButtonSmallNarrow>
          )}
        </div>
        {runs.map(run => (
          <RunLLMCall
            key={run.id}
            args={run}
            onUseAsExpectedAnswer={handleUseAsExpectedAnswer}
            onUseSources={handleUseSources}
            onRemove={handleRemoveRun}
            onUpdate={handleUpdate}
          />
        ))}
        <ExtractKeyPointsFromExpectedAnswers
          expectedAnswers={answers}
          onUseExpectedAnswer={handleUseExtractedAnswer}
        />
      </ClearyCard>

      <div className='d-flex gap-3 align-items-center'>
        <Button
          type='submit'
          disabled={isSaving || !isValid}
          showLoadingSpinner={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>

        {showDelete && (
          <Button
            type='button'
            variant='danger'
            onClick={onDelete}
            disabled={isDeleting}
            showLoadingSpinner={isDeleting}
            confirm={{
              title: 'Delete Question',
              description: 'Are you sure you want to delete this question? This action cannot be undone.',
              variant: 'danger',
            }}
          >
            {isDeleting ? 'Deleting...' : 'Delete'}
          </Button>
        )}
      </div>
    </form>
  )
}

export default QuestionForm
