import React, { useState } from 'react'
import { Button } from 'components/common/buttons'
import LabeledFormFieldContainer from 'components/form_fields/labeledFormFieldContainer'
import { AiEvaluationDatasetType } from 'types/ai/evaluation'

type DatasetFormData = Partial<AiEvaluationDatasetType>

type DatasetFormProps = {
  onSubmit: (data: DatasetFormData) => void
  onDelete?: () => void
  isSaving: boolean
  isDeleting?: boolean
  initialWorkingCopy?: DatasetFormData
  showDelete?: boolean
}

const DatasetForm = ({
  onSubmit,
  onDelete,
  isSaving,
  isDeleting,
  initialWorkingCopy,
  showDelete,
}: DatasetFormProps) => {
  const [workingCopy, setWorkingCopy] = useState<DatasetFormData>(
    {
      description: '',
      runDaily: false,
      ...initialWorkingCopy,
    }
  )

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value, type } = e.target
    const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value
    setWorkingCopy(prev => ({ ...prev, [name]: newValue }))
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSubmit(workingCopy)
  }

  const isValid = workingCopy.description && workingCopy.description.trim() !== ''
  const hasQuestions = (workingCopy.questionsCount ?? 0) > 0

  return (
    <form onSubmit={handleSubmit} className='AiEvaluationDatasetForm'>
      <LabeledFormFieldContainer
        label='Description'
        isRequired
      >
        <textarea
          rows={3}
          name='description'
          value={workingCopy.description}
          onChange={handleChange}
          placeholder='Enter dataset description'
          required
        />
      </LabeledFormFieldContainer>

      <LabeledFormFieldContainer
        label='Run Daily'
        hasTiptapEditor
      >
        <input
          type='checkbox'
          name='runDaily'
          checked={workingCopy.runDaily}
          onChange={handleChange}
        />
      </LabeledFormFieldContainer>

      <div className='d-flex gap-3 align-items-center'>
        <Button
          type='submit'
          disabled={isSaving || !isValid}
          showLoadingSpinner={isSaving}
        >
          {isSaving ? 'Saving...' : 'Save'}
        </Button>

        {showDelete && (
          <div className='d-flex align-items-center gap-3'>
            <Button
              type='button'
              variant='danger'
              onClick={onDelete}
              disabled={isDeleting || hasQuestions}
              showLoadingSpinner={isDeleting}
              confirm={hasQuestions ? undefined : {
                title: 'Delete Dataset',
                description: 'Are you sure you want to delete this dataset? This action cannot be undone.',
                variant: 'danger',
              }}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
            {hasQuestions && (
              <span className='text-danger'>
                Cannot delete dataset with existing questions
              </span>
            )}
          </div>
        )}
      </div>
    </form>
  )
}

export default DatasetForm
