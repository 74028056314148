import AiEvaluationRunsTable from 'pages/admin/ai/aiEvaluationRunsTable'
import AiEvaluationDatasetsTable from 'pages/admin/ai/aiEvaluationDatasetsTable'
import React from 'react'
import { i18nPath } from 'utils/i18nHelpers'

const I18N = i18nPath('views.ai_evaluation')

const AiEvaluationPage = () => (
  <>
    <header className='AdminHeader d-flex justify-content-between'>
      <h3 className='mb-0'>{I18N('title')}</h3>
    </header>

    <main className='AdminContent'>
      <AiEvaluationRunsTable />
      <hr className='my-5' />
      <AiEvaluationDatasetsTable />
    </main>
  </>
)

export default AiEvaluationPage
