import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import React from 'react'
import API from 'services/api'
import { AiEvaluationDatasetType } from 'types/ai/evaluation'
import { ButtonLink } from 'components/common/buttons'
import CdnSvg from 'components/common/cdnSvg'
import { useHistory } from 'react-router-dom'


const AiEvaluationDatasetsTable = () => {
  const {
    data, isLoading, paginationData, callApi, isLoaded,
  } = useFetch(options => API.admin.ai.evaluation.datasets.fetchAll({ ...options, perPage: 10 }))

  const history = useHistory()

  const columns = [
    {
      header: 'Description',
      col: 'col-description',
      accessor: (dataset: AiEvaluationDatasetType) => (
        <div>
          <div>{dataset.description}</div>
          <div className='mt-2 text-secondary text-small'>ID: {dataset.id}</div>
        </div>
      ),
    },
    {
      header: 'Questions Count',
      col: 'col-questions-count',
      accessor: (dataset: AiEvaluationDatasetType) => dataset.questionsCount || 0,
    },
  ]

  const onRowClick = (dataset: AiEvaluationDatasetType) => {
    history.push(`/admin/ai_evaluation/datasets/${dataset.id}/edit`)
  }

  if (!isLoaded) return <CirclesLoadingIndicator className='TableLoadingIndicator' />

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center mb-3'>
        <h3>Datasets</h3>
        <ButtonLink to='/admin/ai_evaluation/datasets/new'>
          <CdnSvg src='/images/plusIcon.svg' className='mr-2 create-dataset-icon' style={{ width: '15px' }} />
          Create Dataset
        </ButtonLink>
      </div>

      <SmartTable
        isLoading={isLoading}
        LoadingComponent={<CirclesLoadingIndicator className='TableLoadingIndicator' />}
        columns={columns}
        data={data}
        className='white-bg-table table-with-row-hover'
        showPagination
        page={paginationData.page}
        pages={paginationData.totalPages}
        onPaginationClick={callApi}
        onSortChange={() => {}}
        sortKey='description'
        sortedAsc={false}
        onRowClick={onRowClick}
      />
    </div>
  )
}

export default AiEvaluationDatasetsTable
