import AnswerEvaluation from 'components/admin/ai/answerEvaluation'
import RunInformation from 'components/admin/ai/runInformation'
import BackButton from 'components/common/backButton'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import useFetch from 'components/common/hooks/useFetch'
import SmartTable from 'components/common/tables/smartTable'
import UserLink from 'components/common/userLink'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import React, {
  Dispatch, SetStateAction, useMemo, useRef, useState
} from 'react'
import API from 'services/api'
import { AiEvaluationAnswerType, AiEvaluationRunType } from 'types/ai/evaluation'
import useQueryParamState from 'components/common/hooks/useQueryParamsState'
import ReactSelect from 'components/common/react_select'
import useQueryParams from 'components/common/hooks/useQueryParams'

type FilterOperator = 'gte' | 'lte' | 'eq'

type MetricField =
  | 'answerRelevanceScore'
  | 'faithfulnessScore'
  | 'expectedAnswerScore'
  | 'answerConcisenessScore'
  | 'contextRelevanceScore'
  | 'ragasScore'
  | 'latencyTime'
  | 'promptTokens'
  | 'completionTokens'
  | 'totalTokens'
  | 'promptCost'
  | 'completionCost'
  | 'totalCost'

const METRIC_LABELS: Record<MetricField, string> = {
  answerRelevanceScore: 'Answer Relevance Score',
  faithfulnessScore: 'Faithfulness Score',
  expectedAnswerScore: 'Answer Score',
  answerConcisenessScore: 'Answer Conciseness Score',
  contextRelevanceScore: 'Context Relevance Score',
  ragasScore: 'RAGAS Score',
  latencyTime: 'Latency Time',
  promptTokens: 'Prompt Tokens',
  completionTokens: 'Completion Tokens',
  totalTokens: 'Total Tokens',
  promptCost: 'Prompt Cost',
  completionCost: 'Completion Cost',
  totalCost: 'Total Cost',
}

type Filter = {
  field: MetricField
  operator: FilterOperator
  value: number
}

type SortOrder = 'asc' | 'desc'

type SortField = MetricField | 'questionContent'

type Sort = {
  field: SortField
  order: SortOrder
}

const SORT_LABELS: Record<SortField, string> = {
  ...METRIC_LABELS,
  questionContent: 'Question Content',
}

interface FilterSelectorProps {
  onAddFilter: (filter: Filter) => void
  onRemoveFilter: (index: number) => void
  filters: Filter[]
}

const getOperatorSymbol = (operator: FilterOperator): string => {
  switch (operator) {
  case 'gte':
    return '≥'
  case 'lte':
    return '≤'
  case 'eq':
    return '='
  default:
    return ''
  }
}

const FilterSelector: React.FC<FilterSelectorProps> = ({ onAddFilter, onRemoveFilter, filters }) => {
  const [field, setField] = useState<MetricField>('answerRelevanceScore')
  const [operator, setOperator] = useState<FilterOperator>('gte')
  const [value, setValue] = useState<string>('')

  const metricOptions = Object.entries(METRIC_LABELS).map(([key, label]) => ({
    value: key,
    label,
  }))

  const operatorOptions = [
    { value: 'gte', label: '≥' },
    { value: 'lte', label: '≤' },
    { value: 'eq', label: '=' },
  ]

  const handleAddFilter = () => {
    if (!value) return
    onAddFilter({
      field,
      operator,
      value: parseFloat(value),
    })
    setValue('')
  }

  return (
    <div>
      <div className='d-flex align-items-center gap-2 mb-2'>
        <div style={{ width: '300px' }}>
          <ReactSelect
            value={metricOptions.find(option => option.value === field)}
            onChange={option => setField(option?.value as MetricField)}
            options={metricOptions}
          />
        </div>

        <div style={{ width: '100px' }}>
          <ReactSelect
            value={operatorOptions.find(option => option.value === operator)}
            onChange={option => setOperator(option?.value as FilterOperator)}
            options={operatorOptions}
          />
        </div>

        <input
          type='number'
          className='form-control'
          value={value}
          onChange={e => setValue(e.target.value)}
          placeholder='Value'
          style={{ width: '120px' }}
        />

        <button
          className='btn btn-primary'
          onClick={handleAddFilter}
          disabled={!value}
        >
          Add Filter
        </button>
      </div>

      {filters.length > 0 && (
        <div className='d-flex flex-wrap gap-2'>
          {filters.map((filter, index) => (
            <div key={index} className='badge bg-primary d-flex align-items-center gap-2 text-white'>
              <span>
                {METRIC_LABELS[filter.field]} {getOperatorSymbol(filter.operator)} {filter.value}
              </span>
              <button
                type='button'
                className='btn-close btn-close-white'
                onClick={() => onRemoveFilter(index)}
                style={{ fontSize: '0.5rem' }}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

interface SortSelectorProps {
  onSort: (sort: Sort) => void
  currentSort: Sort
}

const SortSelector: React.FC<SortSelectorProps> = ({ onSort, currentSort }) => {
  const sortOptions = Object.entries(SORT_LABELS).map(([key, label]) => ({
    value: key,
    label,
  }))

  const field = currentSort.field
  const order = currentSort.order

  const orderOptions = [
    { value: 'desc', label: '↓ Descending' },
    { value: 'asc', label: '↑ Ascending' },
  ]

  return (
    <div className='d-flex align-items-center gap-2'>
      <div style={{ width: '300px' }}>
        <ReactSelect
          value={sortOptions.find(option => option.value === field)}
          onChange={(option) => {
            onSort({ field: option?.value as SortField, order: currentSort.order })
          }}
          options={sortOptions}
        />
      </div>

      <div style={{ width: '200px' }}>
        <ReactSelect
          value={orderOptions.find(option => option.value === order)}
          onChange={(option) => {
            onSort({ field: currentSort.field, order: option?.value as SortOrder })
          }}
          options={orderOptions}
        />
      </div>
    </div>
  )
}

interface LoadRunProps {
  runId: string
  setAnswers: Dispatch<SetStateAction<AiEvaluationAnswerType[]>>
  setRuns: Dispatch<SetStateAction<Record<string, AiEvaluationRunType>>>
}

const LoadRun = ({ runId, setAnswers, setRuns }: LoadRunProps) => {
  const { data: run } = useFetch<AiEvaluationRunType>(
    API.admin.ai.evaluation.runs.fetch,
    [runId]
  )

  const {
    data, isLoading, paginationData, callApi,
  } = useFetch(
    params => API.admin.ai.evaluation.answers.fetchAll(runId, params)
  )

  React.useEffect(() => {
    if (!run) return
    setRuns(prev => ({ ...prev, [runId]: run }))
  }, [run, runId, setRuns])

  React.useEffect(() => {
    if (!data) return
    setAnswers(prev => [...prev, ...data])
  }, [data, setAnswers])

  React.useEffect(() => {
    if (!paginationData?.page || paginationData.page >= (paginationData.totalPages || 0)) return
    callApi({ page: (paginationData.page || 0) + 1 })
  }, [paginationData, callApi])

  if (isLoading && !data) return <CirclesLoadingIndicator className='TableLoadingIndicator' />
  return null
}

export const AiRunComparisonPage = ({
  runIds,
  mainHeaderRight,
}: { runIds: string[], mainHeaderRight?: React.ReactNode }) => {
  const [answers, setAnswers] = useState<AiEvaluationAnswerType[]>([])
  const [runs, setRuns] = useState<Record<string, AiEvaluationRunType>>({})
  const [contentFilter, setContentFilter] = useState('')
  const ref = useRef<string>('')
  const [filters, setFilters] = useQueryParamState<Filter[]>({
    ref,
    param: 'filters',
    initialValue: [],
    asJson: true,
  })
  const [sort, setSort] = useQueryParamState<Sort>({
    ref,
    param: 'sort',
    initialValue: { field: 'answerRelevanceScore', order: 'asc' },
    asJson: true,
  })

  const groupedAnswers = useMemo(() => answers.reduce((acc, answer) => {
    if (!acc[answer.question.id]) {
      acc[answer.question.id] = []
    }

    acc[answer.question.id].push(answer)
    return acc
  }, {} as Record<string, AiEvaluationAnswerType[]>), [answers])

  const filteredGroupedAnswers = useMemo(() => {
    let filtered = groupedAnswers

    // Apply content filter
    if (contentFilter) {
      filtered = Object.entries(filtered).reduce((acc, [questionId, answers]) => {
        const hasMatch = answers.some((answer) => {
          const questionContent = answer.question.content.toLowerCase()
          const answerContent = answer.answer.toLowerCase()
          const searchTerm = contentFilter.toLowerCase()

          return questionContent.includes(searchTerm) || answerContent.includes(searchTerm)
        })
        if (hasMatch) {
          acc[questionId] = answers
        }
        return acc
      }, {} as Record<string, AiEvaluationAnswerType[]>)
    }

    // Apply metric filters
    if (filters.length === 0) return filtered

    return Object.entries(filtered).reduce((acc, [questionId, answers]) => {
      const hasMatchingAnswer = answers.some(answer => filters.every((filter) => {
        const value = answer[filter.field]
        if (value === undefined || value === null) return false

        switch (filter.operator) {
        case 'gte':
          return value >= filter.value
        case 'lte':
          return value <= filter.value
        case 'eq':
          return value === filter.value
        default:
          return true
        }
      }))

      if (hasMatchingAnswer) {
        acc[questionId] = answers
      }
      return acc
    }, {} as Record<string, AiEvaluationAnswerType[]>)
  }, [groupedAnswers, filters, contentFilter])

  const sortedGroupedAnswers = useMemo(() => {
    const questionIds = Object.keys(filteredGroupedAnswers)

    const sortedQuestionIds = questionIds.sort((a, b) => {
      const answersA = filteredGroupedAnswers[a]
      const answersB = filteredGroupedAnswers[b]

      if (sort.field === 'questionContent') {
        const contentA = answersA[0]?.question.content || ''
        const contentB = answersB[0]?.question.content || ''
        return sort.order === 'asc'
          ? contentA.localeCompare(contentB)
          : contentB.localeCompare(contentA)
      }

      // For metric fields, we'll take the average across all runs
      const avgA = answersA.reduce((sum, ans) => sum + (ans[sort.field] || 0), 0) / answersA.length
      const avgB = answersB.reduce((sum, ans) => sum + (ans[sort.field] || 0), 0) / answersB.length

      return sort.order === 'asc' ? avgA - avgB : avgB - avgA
    })

    return sortedQuestionIds.reduce((acc, questionId) => {
      acc[questionId] = filteredGroupedAnswers[questionId]
      return acc
    }, {} as Record<string, AiEvaluationAnswerType[]>)
  }, [filteredGroupedAnswers, sort])

  const openInNewTab = (answer: AiEvaluationAnswerType) => {
    window.open(`/admin/ai_evaluation/answers/${answer.id}`, '_blank')
  }

  const columns = [
    {
      header: 'Question',
      col: 'col-question',
      style: { width: '200px' },
      accessor: (answerGroup: Record<string, AiEvaluationAnswerType>) => {
        const firstAnswer = Object.values(answerGroup)[0]
        return (
          <div>
            <div>{firstAnswer?.question.content}</div>
            <div className='mt-2'><UserLink user={firstAnswer?.question.user} /></div>
            <div className='mt-1 text-secondary text-small'>
              {firstAnswer?.question.companyLogoUrl ? (
                <img
                  src={firstAnswer.question.companyLogoUrl}
                  alt={firstAnswer.question.companyName}
                  className='mr-2'
                  style={{ maxWidth: '100px', maxHeight: '50px' }}
                />
              ) : (
                <div>{firstAnswer?.question.companyName}</div>
              )}
            </div>
          </div>
        )
      },
    },
    {
      header: 'Expected Answer',
      col: 'col-expected-answer',
      accessor: (answers: AiEvaluationAnswerType[]) => {
        const firstAnswer = answers[0]
        return <AiAnswerContent content={firstAnswer.expectedAnswer} />
      },
    },
    ...runIds.map(runId => ({
      header: `Run ${runId}`,
      col: `col-run-${runId}`,
      accessor: (answers: AiEvaluationAnswerType[]) => {
        const answer = answers.find(a => a.run.id === runId)
        if (!answer) return <span>-</span>

        return (
          <div onClick={() => openInNewTab(answer)} style={{ cursor: 'pointer' }}>
            <AiAnswerContent content={answer.answer} />
            <div className='mt-3 comparison-answer-evaluation'>
              <AnswerEvaluation answer={answer} />
            </div>
          </div>
        )
      },
    })),
  ]

  const data = Object.values(sortedGroupedAnswers)

  const handleAddFilter = (filter: Filter) => {
    setFilters([...filters, filter])
  }

  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index))
  }

  return (
    <>
      <header className='AdminHeader d-flex justify-content-between'>
        <h3 className='mb-0'>Run Comparison</h3>
      </header>

      <main className='AdminContent'>
        <div className='d-flex justify-content-between align-items-center mb-4'>
          <BackButton url='/admin/ai_evaluation' className='mb-4' />
          {mainHeaderRight}
        </div>

        {runIds.map(runId => (
          <RunInformation key={runId} run={runs[runId]} showTitle />
        ))}

        {runIds.map(runId => (
          <LoadRun
            key={runId}
            runId={runId}
            setAnswers={setAnswers}
            setRuns={setRuns}
          />
        ))}

        <div className='d-flex flex-column gap-3 mb-4'>
          <div className='d-flex justify-content-between gap-3 align-items-start'>
            <FilterSelector
              filters={filters}
              onAddFilter={handleAddFilter}
              onRemoveFilter={handleRemoveFilter}
            />

            <SortSelector onSort={setSort} currentSort={sort} />
          </div>

          <input
            type='text'
            className='form-control'
            placeholder='Filter by question or answer content...'
            value={contentFilter}
            onChange={e => setContentFilter(e.target.value)}
          />
        </div>

        <SmartTable
          columns={columns}
          data={data}
          className='white-bg-table'
          showPagination={false}
        />
      </main>
    </>
  )
}

const AiRunComparisonPageByQueryParams = () => {
  const { runIds = '' } = useQueryParams()
  return <AiRunComparisonPage runIds={runIds.split(',')} />
}

export default AiRunComparisonPageByQueryParams
