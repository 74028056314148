import React, { useState, useMemo } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import ClearyCard from 'components/common/card'
import CollapsableItem from 'components/common/collapsableItem'
import { ButtonSmallNarrow } from 'components/common/buttons'
import AiAnswerContent from 'components/search/ai/aiAnswerContent'
import API from 'services/api'
import { formatFloat, present } from 'components/common/utils'
import useFetch from 'components/common/hooks/useFetch'
import CirclesLoadingIndicator from 'components/common/circlesLoadingIndicator'
import ReactSelect from 'components/common/react_select'
import { MODEL_OPTIONS } from 'pages/admin/ai/utils'
import MessageEditor, { MessageType } from './messageEditor'

type RunArgs = {
  args: string
  timestamp: string
}

const extractSourceLink = (index: number, content: string) => {
  try {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(content, 'text/xml')
    const records = Array.from(xmlDoc.getElementsByTagName('record'))

    const matchingRecord = records.find((record) => {
      const indexElement = record.getElementsByTagName('index')[0]
      const indexContent = indexElement?.textContent
      return indexContent === index.toString()
    })

    const linkElement = matchingRecord?.getElementsByTagName('link')[0]
    const linkContent = linkElement?.textContent

    return linkContent || null
  } catch (error) {
    console.error('Error parsing XML:', error)
    return null
  }
}


const RunLLMCall = ({ args, timestamp }: RunArgs) => {
  const [response, setResponse] = useState<string | null>(null)
  const [toolCalls, setToolCalls] = useState<any[]>([])

  const toolCallMessage = useMemo(() => {
    try {
      const { messages } = JSON.parse(args)
      return messages?.find(message => message.tool_call_id && message?.content?.startsWith('<?xml'))
    } catch {
      return null
    }
  }, [args])

  const { isLoading } = useFetch(() => API.admin.ai.callLLM({ args }), [], {
    onSuccess(data) {
      setToolCalls(data.toolCalls)

      if (typeof data.response === 'object') {
        setResponse(JSON.stringify(data.response, null, 2))
      } else {
        setResponse(data.response)
      }
    },
  })

  const sources = useMemo(() => {
    // Extract source indices from response
    const sourceIndices = (response?.match(/<sup>(\d+)<\/sup>/g) || []).map(index => parseInt(index.replace(/<\/?sup>/g, '')))

    if (sourceIndices.length > 0 && toolCallMessage?.content) {
      const links = sourceIndices.map(index => extractSourceLink(index, toolCallMessage.content)).filter(present)
      return _.uniq(links)
    }

    return []
  }, [toolCallMessage, response])


  const model = useMemo(() => JSON.parse(args).model, [args])

  return (
    <div className='mb-4 border-bottom pb-4'>
      <h5>Run - {model} - {new Date(timestamp).toLocaleString()}</h5>
      {isLoading && <CirclesLoadingIndicator />}
      {response && (
        <div className='mt-2'>
          <h6>Response:</h6>
          <AiAnswerContent content={response} />
        </div>
      )}

      {sources.length > 0 && (
        <div className='mt-2'>
          <hr />
          <h6>Sources:</h6>
          <ul>
            {sources.map((source, index) => (
              <li key={index}>
                <a href={source} target='_blank' rel='noopener noreferrer'>
                  {source}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}

      {!_.isEmpty(toolCalls) && (
        <div className='mt-2'>
          <h6>Tool Calls:</h6>
          <pre>{JSON.stringify(toolCalls, null, 2)}</pre>
        </div>
      )}
    </div>
  )
}

const EditableLlmCall = ({ llmCall, defaultIsCollapsed }: { llmCall: any, defaultIsCollapsed: boolean }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(JSON.stringify(llmCall, null, 2))
  const [runArgs, setRunArgs] = useState<RunArgs[]>([])

  const parsedValue = useMemo(() => {
    try {
      const parsed = JSON.parse(value)

      if (parsed.messages) {
        parsed.messages.forEach((message) => {
          if (Array.isArray(message.content)) {
            message.content = message.content[0].text
          }
        })
      }

      return parsed
    } catch {
      return {}
    }
  }, [value])

  const messages = parsedValue.messages

  const setMessage = (index: number, message: MessageType) => {
    setValue((prev) => {
      const parsed = JSON.parse(prev)
      parsed.messages[index] = message
      return JSON.stringify(parsed, null, 2)
    })
  }

  const handleModelChange = (model: string) => {
    setValue((prev) => {
      const parsed = JSON.parse(prev)
      parsed.model = model
      return JSON.stringify(parsed, null, 2)
    })
  }

  const prepareArgs = () => {
    const parsedValue = JSON.parse(value)
    const deepSnakeCase = (obj: any): any => {
      if (Array.isArray(obj)) {
        return obj.map(deepSnakeCase)
      }
      if (obj !== null && typeof obj === 'object') {
        return Object.keys(obj).reduce((acc, key) => {
          acc[_.snakeCase(key)] = deepSnakeCase(obj[key])
          return acc
        }, {} as any)
      }
      return obj
    }

    return JSON.stringify({
      ...deepSnakeCase(parsedValue),
      type: 'langchain_client',
      method: 'chat',
    })
  }

  const onAddRuns = () => {
    const args = prepareArgs()
    const newRun = {
      args,
      timestamp: new Date().toISOString(),
    }
    setRunArgs([...runArgs, newRun])
  }

  const onClearRuns = () => {
    setRunArgs([])
  }

  return (
    <ClearyCard className='mb-3'>
      <CollapsableItem
        defaultIsCollapsed={defaultIsCollapsed}
        label={(
          <div className='d-flex gap-4'>
            <div>
              <div className='text-secondary text-small'>Model</div>
              <div className='text-small'>{llmCall.model}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Prompt tokens - cost</div>
              <div className='text-small'>{llmCall.usage.promptTokens} - ${formatFloat(llmCall.usage.promptCost, 6)}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Completion tokens - cost</div>
              <div className='text-small'>{llmCall.usage.completionTokens} - ${formatFloat(llmCall.usage.completionCost, 6)}</div>
            </div>
            <div>
              <div className='text-secondary text-small'>Total tokens - cost</div>
              <div className='text-small'>{llmCall.usage.totalTokens} - ${formatFloat(llmCall.usage.totalCost, 6)}</div>
            </div>
          </div>
        )}
      >
        <div className='mb-3'>
          <ButtonSmallNarrow onClick={() => setIsEditing(!isEditing)}>
            {isEditing ? 'Cancel Edit' : 'Edit'}
          </ButtonSmallNarrow>
        </div>

        {isEditing ? (
          <>
            {messages?.length > 0 && (
              <div className='mb-3'>
                <h4>Messages:</h4>
                {messages.map((message, index) => (
                  <MessageEditor
                    key={index}
                    value={message}
                    onChange={message => setMessage(index, message)}
                  />
                ))}
              </div>
            )}

            <div className='mb-3'>
              <h4>Model:</h4>
              <ReactSelect
                value={MODEL_OPTIONS.find(option => option.value === parsedValue.model)}
                onChange={option => handleModelChange(option.value)}
                options={MODEL_OPTIONS}
              />
            </div>

            {parsedValue.tools && (
              <div className='mb-3'>
                <h4>Tools:</h4>
                <textarea
                  className={classNames('w-100 p-2', { 'is-invalid': typeof parsedValue.tools === 'string' })}
                  value={typeof parsedValue.tools === 'string' ? parsedValue.tools : JSON.stringify(parsedValue.tools, null, 2)}
                  onChange={(e) => {
                    setValue((prev) => {
                      const parsed = JSON.parse(prev)
                      try {
                        parsed.tools = JSON.parse(e.target.value)
                      } catch {
                        parsed.tools = e.target.value
                      }
                      return JSON.stringify(parsed, null, 2)
                    })
                  }}
                  rows={10}
                />
                {typeof parsedValue.tools === 'string' && (
                  <div className='text-danger mb-2'>Error: Tools must be a valid JSON object</div>
                )}
              </div>
            )}

            <div className='mb-3'>
              <h4>Full payload:</h4>
              <textarea
                className='w-100 p-2'
                value={value}
                onChange={e => setValue(e.target.value)}
                rows={10}
              />
            </div>

            <div className='d-flex gap-3 align-items-center mb-3'>
              <div className='d-flex gap-2 align-items-center'>
                <ButtonSmallNarrow onClick={onAddRuns}>
                  Execute new run
                </ButtonSmallNarrow>

                {runArgs.length > 0 && (
                  <ButtonSmallNarrow variant='secondary-danger' onClick={onClearRuns}>
                    Clear all runs
                  </ButtonSmallNarrow>
                )}
              </div>
            </div>
          </>
        ) : (
          <pre style={{ whiteSpace: 'pre-wrap' }} className='text-small'>
            {JSON.stringify(_.pick(llmCall, ['messages', 'tools', 'response', 'toolCalls']), null, 2)}
          </pre>
        )}

        {runArgs.length > 0 && (
          <div className='mt-4'>
            <h4>Runs:</h4>
            {runArgs.map((args, index) => (
              <RunLLMCall key={`${args.timestamp}-${index}`} {...args} />
            ))}
          </div>
        )}
      </CollapsableItem>
    </ClearyCard>
  )
}

export default EditableLlmCall
